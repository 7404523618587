// BOOTSTRAP 4 VARIABLES

@import "components/bootstrap4/functions";
@import "components/bootstrap4/variables";
@import "components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "components/bootstrap4/root";
@import "components/bootstrap4/reboot";
@import "components/bootstrap4/type";
@import "components/bootstrap4/images";
@import "components/bootstrap4/code";
@import "components/bootstrap4/grid";
@import "components/bootstrap4/tables";
@import "components/bootstrap4/forms";
@import "components/bootstrap4/buttons";
@import "components/bootstrap4/transitions";
@import "components/bootstrap4/dropdown";
@import "components/bootstrap4/button-group";
@import "components/bootstrap4/input-group";
@import "components/bootstrap4/custom-forms";
@import "components/bootstrap4/nav";
@import "components/bootstrap4/navbar";
@import "components/bootstrap4/card";
@import "components/bootstrap4/breadcrumb";
@import "components/bootstrap4/pagination";
@import "components/bootstrap4/badge";
@import "components/bootstrap4/jumbotron";
@import "components/bootstrap4/alert";
@import "components/bootstrap4/progress";
@import "components/bootstrap4/media";
@import "components/bootstrap4/list-group";
@import "components/bootstrap4/close";
@import "components/bootstrap4/toasts";
@import "components/bootstrap4/modal";
@import "components/bootstrap4/tooltip";
@import "components/bootstrap4/popover";
@import "components/bootstrap4/carousel";
@import "components/bootstrap4/spinners";
@import "components/bootstrap4/utilities";
@import "components/bootstrap4/print";

// LAYOUT

@import "layout/layout";

// UTILS

@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/dropdown";
@import "elements/accordions";
@import "elements/modals";
@import "elements/timeline";
@import "elements/popovers-tooltips";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";

// PAGES
// //@import "pages/userpages";

// COMPONENTS

// Perfect Scrollbar

@import "components/perfect-scrollbar/perfect-scrollbar";

// Hamburger button

 @import "components/hamburgers/hamburgers";

// Icons

@import "components/icons/p7icons";

// Responsive

@import "layout/responsive/responsive-base";

// DEMO

//@import "demo-ui/demo";

.ag-root ::-webkit-scrollbar 
{
    width: 10px;
    margin-left: -10px;
    -webkit-appearance: none;

    &-thumb {
        height: 50px;
        background-color: #999;
        background-clip: content-box;
        border-color: transparent;
        border-style: solid;
        border-width: 1px 2px;
    }

    &-button 
    {
        width: 0 !important;
        height: 0 !important;
        background: #ccc;
    }

    &-track 
    {
        background-color: #ccc;
        border-right: 1px solid #eee;
        border-left: 1px solid #eee;
    }
}

.ag-root {
  .ag-header {
    height: 30px !important;
    min-height: 30px  !important;
  }

  .ag-body-horizontal-scroll,
  .ag-body-horizontal-scroll-viewport {
    min-height: 9px !important;
    max-height: 9px !important;
    height: 9px !important;
  }
}

/********************************************
 *              DEVEXTREME                  *
 ********************************************/

.form-wrapper .dx-button {
  height: 30px !important;
  border-radius: 30px !important;
}

.dx-button-text {
    text-transform: capitalize !important;
}

.dx-button-warning {
  color: orange;  
  border-color: orange;  
}

.toolbar-label,
.toolbar-label > b {
    font-size: 16px;
}

.dx-toolbar {
  border-radius: 5px;
  border-bottom: 1px solid #2CBF96;
  margin-bottom: 15px;
}

.ag-theme-material {
  border-radius: 5px;
  border-bottom: 1px solid #2CBF96;

  > .ag-root-wrapper {
    border-radius: 5px;
  }
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #2CBF96 !important;
}

.widget-container .dx-item.dx-list-item {
  color: #2CBF96 !important;
  text-decoration: underline;
}

.item:focus {
  outline: 0px;
}

.ag-theme-material .ag-cell {
  line-height: 30px !important;
}

.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
  background-color: transparent;
}

#breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
}

/**
  * Base Layaout
  */
.perfect-scrollbar-wrapper {
    overflow-y: auto;
}

.perfect-scrollbar-wrapper__inner {
    padding: 30px;
}

/**
  * Forms 
  */
#form-container {
  padding: 30px;
  border-radius: 5px;
  border-bottom: 1px solid #2CBF96;
  margin-bottom: 15px;
  background-color: #fff;
}

/**
  * Tickets
  */
.badge-wrapper > .badge > .act-anul {
  .dx-button-content {
    padding: 0;
  }

  .dx-icon {
    color: #fff;
    font-size: 75%;
    line-height: 8px;
  }
}