// Header User Area

.header-btn-lg {
  // padding: 0 0 0 ($layout-spacer-x);
  // margin-left: ($layout-spacer-x);
  display: flex;
  align-items: center;
  position: relative;

  .dropdown-toggle::after {
    display: none;
    // position: absolute;
    // top: 50%;
    // margin-top: -10px;
    // right: -3px;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background: $gray-600;
  }

}
